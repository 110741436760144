import {combineReducers} from "redux";
import buckReducer from "./buck/buck.reducer";
import { RootState, SidebarState, initialSidebarState } from "./root.state";
import { changeToWebMode, checkIsRunningOnLocalhost, gotoDashboard, toggleExpandAll, toggleSearchBucksIsExpanded, toggleSearchHistoryIsExpanded, toggleSearchSNIsExpanded, toggleSearchValuableMatchesIsExpanded } from "./root.actions";

const rootInitialState:RootState = {
    hasError: false,
    isLocalMode: true,
    error: '',
    modeName: 'web',
    sidebarState: initialSidebarState,
    isDashboard: true,
    isRunningOnLocalHost: true,
} 

const reducer = (state = rootInitialState, action:any) => {
    var isLocal = window.location.href.indexOf('localhost:') > -1;
    // var isLocal = false;
    switch (action.type) {
        case gotoDashboard.type:
            return {
                ...state,
                isDashboard: true,
            };
        case changeToWebMode.type:
            return {
                ...state,
                modeName: isLocal ? action.payload ? 'web' : 'local' : 'web',
                isLocalMode: isLocal ? !action.payload : false,
                sidebarState: initialSidebarState,
                isDashboard: false,
            };
        case checkIsRunningOnLocalhost.type:
            console.log("checkIsRunningOnLocalhost", isLocal);
            
            return {
                ...state,
                isLocalMode: isLocal,
                isRunningOnLocalhost: isLocal,
            };
        case toggleExpandAll.type :
            let newSidebarState:SidebarState;
            if(!state.sidebarState.allExpanded){
                newSidebarState = {
                    ...state.sidebarState, 
                    allExpanded: true,
                    searchSNIsExpanded: true,
                    searchBucksIsExpanded: true,
                    searchHistoryIsExpanded: true,
                    searchValuableMatchesIsExpanded: true,
                }
            } else {
                newSidebarState = {
                    ...state.sidebarState, 
                    allExpanded: false,
                    searchSNIsExpanded: false,
                    searchBucksIsExpanded: false,
                    searchHistoryIsExpanded: false,
                    searchValuableMatchesIsExpanded: false,
                }
            }
            return {
                ...state,
                sidebarState: newSidebarState,
            };
        case toggleSearchSNIsExpanded.type :
            return {
                ...state,
                sidebarState: {...state.sidebarState, searchSNIsExpanded: !state.sidebarState.searchSNIsExpanded},
            };
        case toggleSearchBucksIsExpanded.type :
            return {
                ...state,
                sidebarState: {...state.sidebarState, searchBucksIsExpanded: !state.sidebarState.searchBucksIsExpanded},
            };
        case toggleSearchHistoryIsExpanded.type :
            return {
                ...state,
                sidebarState: {...state.sidebarState, searchHistoryIsExpanded: !state.sidebarState.searchHistoryIsExpanded},
            };
        case toggleSearchValuableMatchesIsExpanded.type :
            return {
                ...state,
                sidebarState: {...state.sidebarState, searchValuableMatchesIsExpanded: !state.sidebarState.searchValuableMatchesIsExpanded},
            };
        default:
            return state;
    }
};

const rootReducer = combineReducers({ reducer, buckReducer});

export default rootReducer
