import { createAction, nanoid } from '@reduxjs/toolkit'

export const ChangeToWebMode = 'ChangeToWebMode';
export const CheckIsRunningOnLocalhost = 'CheckIsRunningOnLocalhost';
export const GotoDashboard = 'GotoDashboard';

export const ToggleSearchSNIsExpanded = 'ToggleSearchSNIsExpanded';
export const ToggleSearchBucksIsExpanded = 'ToggleSearchBucksIsExpanded';
export const ToggleSearchHistoryIsExpanded = 'ToggleSearchHistoryIsExpanded';
export const ToggleSearchValuableMatchesIsExpanded = 'ToggleSearchValuableMatchesIsExpanded';
export const ToggleExpandAll = 'ToggleExpandAll';


export const gotoDashboard = createAction(GotoDashboard);
export const changeToWebMode = createAction<boolean>(ChangeToWebMode);
export const checkIsRunningOnLocalhost = createAction(CheckIsRunningOnLocalhost);

export const toggleExpandAll = createAction(ToggleExpandAll);
export const toggleSearchHistoryIsExpanded = createAction(ToggleSearchHistoryIsExpanded);
export const toggleSearchSNIsExpanded = createAction(ToggleSearchSNIsExpanded);
export const toggleSearchBucksIsExpanded = createAction(ToggleSearchBucksIsExpanded);
export const toggleSearchValuableMatchesIsExpanded = createAction(ToggleSearchValuableMatchesIsExpanded);