import styles from './local.module.scss';
import { useEffect, useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useSelector, useDispatch } from 'react-redux'
import { loadMostRecentValuable } from '../../../store/buck/buck.actions';
import { BuckState } from '../../../store/buck/buck.state';
import { Box } from '@mui/material';
import MatchIconComponent from '../../components/match-icon/match-icon.component';

const LocalComponent = ({ title }: any) => {
    const buckState = useSelector((state:any) => state.buckReducer as BuckState);
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(loadMostRecentValuable());
    }, [dispatch])

    const buckColumns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
          {
            accessorKey: 'SN', //or obj.SN if nested
            header: 'SN',
            size: 150,
          },
          {
            accessorKey: 'RatingValue',
            header: 'Rating Value',
            size: 200,
          },
          {
            accessorKey: 'BatchDate',
            header: 'Batch Date',
            size: 150,
            Cell: ({ cell }) => (
              <span>{ `${new Date(cell.getValue<Date>()).toLocaleDateString()} ${new Date(cell.getValue<Date>()).toLocaleTimeString()}` }</span>
            ),
          },
        ],
        [],
      );

      const noteColumns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
          {
            accessorKey: 'SerialNumber', //or obj.SN if nested
            header: 'Serial Number',
            size: 150,
          },
          {
            accessorKey: 'NoteID',
            header: 'Note ID',
            size: 200,
          },
          {
            accessorKey: 'LastUpdate',
            header: 'Last Updated',
            size: 150,
            Cell: ({ cell }) => (
              <span>{ `${new Date(cell.getValue<Date>()).toLocaleDateString()} ${new Date(cell.getValue<Date>()).toLocaleTimeString()}` }</span>
            ),
          },
        ],
        [],
      );

    return (
      <div className={`${styles['local-container']}`}>
          {buckState.isFetching ? (
              <img src='../images/loading.gif' alt='loading...' width="110px" />
          ):(
              <div className='w-100 h-100 p-lg'>
                  {buckState.showBucks ? (
                      <div>
                          <h1 className={`m-b-lg`}>{buckState.queryTitle} Count: {buckState.bucks.length}</h1>
                          <MaterialReactTable 
                            columns={buckColumns} 
                            data={buckState.bucks} 
                            enablePagination={false} 
                            muiTableBodyCellProps={{
                              sx: {
                                //stripe the rows, make odd rows a darker color
                                border: 'none',
                              },
                            }}
                            muiTableBodyProps={{
                              sx: {
                                //stripe the rows, make odd rows a darker color
                                '& tr': {
                                  position: 'relative',
                                }
                              },
                            }}
                            muiExpandButtonProps={{
                              sx: {
                                width: '1300px',
                                height:'47px',
                                position: 'absolute',
                                left: '71px',
                                top: '2px',
                                textAlign: 'right',
                                justifyContent: 'right',
                                borderRadius: '0px',
                                "&:hover" : {background:'unset'},
                              },}}
                            muiTablePaperProps={{
                              elevation: 0, //change the mui box shadow
                              //customize paper styles
                              sx: {
                                border: 'none',
                              },
                            }}
                            displayColumnDefOptions={{
                              'mrt-row-expand': {
                                muiTableHeadCellProps: {
                                  align: 'right',
                                },
                                muiTableBodyCellProps: {
                                  align: 'right',
                                },
                              },
                            }}
                            renderDetailPanel={({ row }) => (
                              <Box
                                sx={{
                                  margin: 'auto',
                                  width: '100%',
                                  borderBottom: 'none',
                                  marginTop: '-3px'
                                }}
                              >
                                <MatchIconComponent matchName={'IsUniqueDigits'} isTrue={row.original.IsUniqueDigits}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsEuroDate'} isTrue={row.original.IsEuroDate}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsDate'} isTrue={row.original.IsDate}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsTwoDigits'} isTrue={row.original.IsTwoDigits}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsRepeatingPairs4'} isTrue={row.original.IsRepeatingPairs4}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsAllPairs2'} isTrue={row.original.IsAllPairs2}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsPalindrome'} isTrue={row.original.IsPalindrome}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsSixOrMoreSameDigit'} isTrue={row.original.IsSixOrMoreSameDigit}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsRepeatingPairs2'} isTrue={row.original.IsRepeatingPairs2}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsAllPairs4'} isTrue={row.original.IsAllPairs4}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsConsectutivePairDescending'} isTrue={row.original.IsConsectutivePairDescending}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsConsectutivePairAscending'} isTrue={row.original.IsConsectutivePairAscending}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsOneDigit'} isTrue={row.original.IsOneDigit}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsConsectutiveDescending'} isTrue={row.original.IsConsectutiveDescending}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsConsectutiveAscending'} isTrue={row.original.IsConsectutiveAscending}></MatchIconComponent>
                                <MatchIconComponent matchName={'IsStarNote'} isTrue={row.original.IsStarNote}></MatchIconComponent>
                              </Box>
                            )}
                            positionExpandColumn="last"
                          />
                      </div>
                  ) : (
                      <div>
                          <h1>{buckState.queryTitle} Count: {buckState.notes.length}</h1>
                          <MaterialReactTable columns={noteColumns} data={buckState.notes} enablePagination={false} />
                      </div>
                  )}
              </div>
          )}
      
      </div>
    )
}

export default LocalComponent;

