import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { faBanSmoking, faCertificate, faCircleArrowLeft, faCircleArrowRight, faCircleH, faCircleHalfStroke, faCircleLeft, faCircleNotch, faCirclePause, faCirclePlus, faCircleRight, faClock, faClockFour, faExclamationCircle, faSquareFull } from '@fortawesome/free-solid-svg-icons';

const MatchIconComponent = ({ matchName, isTrue }: any) => {

    const getIconFromMatchName = (matchName:string):any => {
        let rtn = faBanSmoking;
        let rarity = 100000000;
        switch (matchName) {
            case 'IsUniqueDigits':
                rtn = faCircleNotch;
                rarity = 1814400;
                break;
            case 'IsStarNote':
                rtn = faCertificate;
                rarity = 1000000;
                break;
            case 'IsEuroDate':
                rtn = faClockFour;
                rarity = 109938;
                break;
            case 'IsDate':
                rtn = faClock;
                rarity = 109938;
                break;
            case 'IsTwoDigits':
                rtn = faCirclePause;
                rarity = 11430;
                break;
            case 'IsRepeatingPairs4':
                rtn = faCircleH;
                rarity = 10000;
                break;
            case 'IsAllPairs2':
                rtn = faCircleH;
                rarity = 10000;
                break;
            case 'IsPalindrome':
                rtn = faCircleHalfStroke;
                rarity = 10000;
                break;
            case 'IsSixOrMoreSameDigit':
                rtn = faCirclePlus;
                rarity = 2800;
                break;
            case 'IsRepeatingPairs2':
                rtn = faCircleH;
                rarity = 100;
                break;
            case 'IsAllPairs4':
                rtn = faCircleH;
                rarity = 100;
                break;
            case 'IsConsectutivePairDescending':
                rtn = faCircleArrowLeft;
                rarity = 97;
                break;
            case 'IsConsectutivePairAscending':
                rtn = faCircleArrowRight;
                rarity = 97;
                break;
            case 'IsOneDigit':
                rtn = faExclamationCircle;
                rarity = 10;
                break;
            case 'IsConsectutiveDescending':
                rtn = faCircleLeft;
                rarity = 3;
                break;
            case 'IsConsectutiveAscending':
                rtn = faCircleRight;
                rarity = 3;
                break;
            default:
                break;
        }
        return [rtn, rarity];
    }

    return (
        <Tooltip title={`${matchName} ${getIconFromMatchName(matchName)[1]}/100,000,000`}><IconButton> {isTrue !== undefined && isTrue !== null && isTrue === true ? <FontAwesomeIcon icon={getIconFromMatchName(matchName)[0]} color="peru" /> : <FontAwesomeIcon icon={faSquareFull} /> }  </IconButton></Tooltip>
    )
}

export default MatchIconComponent;

