import {
    Chart as ChartJS,
    ArcElement,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { memo } from 'react';

ChartJS.register(
  ChartDataLabels,
  ArcElement,
);

const getChartData = (districtChartData:any[], isDistrictDataFetching:boolean) => {
  console.log("isDistrictDataFetching",isDistrictDataFetching);
  let options: any;
  let rtn: any;
  const chartLabelColor = '#efefef';

  options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        formatter: (val:any, ctx:any) => (ctx.chart.data.labels[ctx.dataIndex])
      },
      title: {
        display: true,
        text: '# of Bucks by District',
        color: chartLabelColor,
        font: {
          size: 24
        }
      },
    },
  };

  //const ctx = document.getElementById('chartJSContainer') as any;//.getContext('2d');
  rtn = {
    labels: districtChartData?.map(d=>d.district),
    datasets: [
      {
        data: districtChartData?.map(d=>d.count),
        backgroundColor: [
          '#234227',
          '#006600',
          '#3B6D42',
          '#52775C',
          '#234227',
          '#006600',
          '#3B6D42',
          '#52775C',
          '#234227',
          '#006600',
          '#3B6D42',
          '#52775C',
        ],
        datalabels: {
          color: '#efefef',
          labels: {
            title: {
              font: {
                weight: 'bold'
              }
            },
          }
        }
      },
    ],
  }
  return <Doughnut options={options} data={rtn} />;
} 
  
const DistrictsChartComponent = ({ districtChartData, isDistrictDataFetching }: any) => {
  return ( 
    <>
      { isDistrictDataFetching ? (
        <img src='../images/loading.gif' alt='loading...' width="55px" />
      ) : (
        <>
          {getChartData(districtChartData, isDistrictDataFetching)}
        </>
      )}
    </>
  )
}

export default memo(DistrictsChartComponent);