import { combineEpics, ofType } from 'redux-observable';
import { loadAllImportedEpic, loadAllNotImportedEpic, loadMostRecentValuableEpic, loadAllValuableEpic, doImportEpic, loadBuckEpic, searchWithMatchesEpic, searchTopHistoryEpic, searchBucksEpic, loadAllNotExportedEpic, loadAllExportedEpic, doExportEpic, loadDashboardDistrictCountsEpic, loadDashboardTopHistoryChartDataEpic, loadDashboardTopRatedChartDataEpic } from './buck/buck.epics';
import { Observable, catchError, map, mergeMap, of } from 'rxjs';
import { ChangeToWebMode } from './root.actions';
import { clearBucks } from './buck/buck.actions';
  
export const changeToWebModeEpic = (action$:Observable<any>) => action$.pipe(
    ofType(ChangeToWebMode),
    mergeMap(() => of(ChangeToWebMode).pipe(
        map(() => {
            return clearBucks();
        }),
    )
));

export const rootEpic = combineEpics(
    changeToWebModeEpic,
    loadAllNotImportedEpic,
    loadMostRecentValuableEpic,
    loadAllImportedEpic,
    loadAllValuableEpic,
    doImportEpic,
    loadBuckEpic,
    searchWithMatchesEpic,
    searchTopHistoryEpic,
    searchBucksEpic,
    loadAllExportedEpic,
    loadAllNotExportedEpic,
    doExportEpic,
    loadDashboardDistrictCountsEpic,
    loadDashboardTopHistoryChartDataEpic,
    loadDashboardTopRatedChartDataEpic,
);
  