import styles from './web.module.scss';
import React, { useEffect, useMemo } from 'react';

import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { useSelector, useDispatch } from 'react-redux'
import { ActionLoaded, BuckState } from '../../../store/buck/buck.state';
import { Box } from '@mui/material';
import { RootState } from '../../../store/root.state';
import MatchIconComponent from '../../components/match-icon/match-icon.component';
import { searchTopHistory } from '../../../store/buck/buck.actions';
import { SearchChartComponent } from '../../components/search-chart/search-chart.component';

const WebComponent = ({ title }: any) => {
  const state = useSelector((state:any) => state.reducer as RootState);
  const buckState = useSelector((state:any) => state.buckReducer as BuckState);
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(loadBuck(buckState.buckQuery.searchSN));
    //dispatch(searchWithMatches(buckState.buckQuery.selectedMAT));
    dispatch(searchTopHistory());
  }, [dispatch])


const tableTheme = useMemo(
  () =>
    createTheme({
      palette: {
        mode: 'dark', //let's use the same dark/light mode as the global theme
        background: {
          default: '#0f1b5e',
          paper: '#192461',
        },
      },
    }),
  [],
);

  const buckList = buckState.bucks.map((b) =>
    <Box key={b._id}
    sx={{
      margin: 'auto',
      width: '100%',
      borderBottom: 'none',
      marginTop: '-3px',
      paddingTop: '5px',
      paddingBottom: '5px',
    }}
    >
      <div className='txt-xl'>
        {b._id}
      </div>
      <div className='txt-lg'>
        HIST Count: <span className='txt-bold txt-lg'>{b.HIST?.length}</span>
      </div>
      <div className='txt-lg'>
        Rating: <span className='txt-bold txt-lg'>{b.MAT?.RatingValue}</span>
      </div>
      <div className='txt-lg'>
        Updated: <span className='txt-bold txt-lg'>{b.UDT !== undefined ? `${new Date(b.UDT).toLocaleDateString()} ${new Date(b.UDT).toLocaleTimeString()}` : ''}</span>
      </div>

      <MatchIconComponent matchName={'IsUniqueDigits'} isTrue={b.MAT?.IsUniqueDigits}></MatchIconComponent>
      <MatchIconComponent matchName={'IsEuroDate'} isTrue={b.MAT?.IsEuroDate}></MatchIconComponent>
      <MatchIconComponent matchName={'IsDate'} isTrue={b.MAT?.IsDate}></MatchIconComponent>
      <MatchIconComponent matchName={'IsTwoDigits'} isTrue={b.MAT?.IsTwoDigits}></MatchIconComponent>
      <MatchIconComponent matchName={'IsRepeatingPairs4'} isTrue={b.MAT?.IsRepeatingPairs4}></MatchIconComponent>
      <MatchIconComponent matchName={'IsAllPairs2'} isTrue={b.MAT?.IsAllPairs2}></MatchIconComponent>
      <MatchIconComponent matchName={'IsPalindrome'} isTrue={b.MAT?.IsPalindrome}></MatchIconComponent>
      <MatchIconComponent matchName={'IsSixOrMoreSameDigit'} isTrue={b.MAT?.IsSixOrMoreSameDigit}></MatchIconComponent>
      <MatchIconComponent matchName={'IsRepeatingPairs2'} isTrue={b.MAT?.IsRepeatingPairs2}></MatchIconComponent>
      <MatchIconComponent matchName={'IsAllPairs4'} isTrue={b.MAT?.IsAllPairs4}></MatchIconComponent>
      <MatchIconComponent matchName={'IsConsectutivePairDescending'} isTrue={b.MAT?.IsConsectutivePairDescending}></MatchIconComponent>
      <MatchIconComponent matchName={'IsConsectutivePairAscending'} isTrue={b.MAT?.IsConsectutivePairAscending}></MatchIconComponent>
      <MatchIconComponent matchName={'IsOneDigit'} isTrue={b.MAT?.IsOneDigit}></MatchIconComponent>
      <MatchIconComponent matchName={'IsConsectutiveDescending'} isTrue={b.MAT?.IsConsectutiveDescending}></MatchIconComponent>
      <MatchIconComponent matchName={'IsConsectutiveAscending'} isTrue={b.MAT?.IsConsectutiveAscending}></MatchIconComponent>
      <MatchIconComponent matchName={'IsStarNote'} isTrue={b.MAT?.IsStarNote}></MatchIconComponent>
    </Box>
  );


  return (
    <div className={`${styles['web-container']} p-l-lg p-r-lg p-b-lg p-t-sm`}>
      <ThemeProvider theme={tableTheme}>
          {buckState.isFetching ? (
              <img src='../images/loading.gif' alt='loading...' width="110px" />
          ):(
              <>
                {buckState.buck?.MAT && buckState.buck?.HIST ? (
                  <>
                    <div>
                      <div>
                        <h1>{buckState.queryTitle}</h1>
                        <div className='txt-md txt-italic'>
                            {buckState.queryDesc}
                        </div>
                      </div>
                      <div className='txt-xl m-t-md'>
                        {buckState.buck._id}
                      </div>
                      <div className='txt-lg'>
                        HIST Count: <span className='txt-bold txt-lg'>{buckState.buck.HIST.length}</span>
                      </div>
                      <div className='txt-lg'>
                        Rating: <span className='txt-bold txt-lg'>{buckState.buck.MAT.RatingValue}</span>
                      </div>
                      <div className='txt-lg m-b-sm'>
                        Updated: <span className='txt-bold txt-lg'>{buckState.buck.UDT ? `${new Date(buckState.buck.UDT).toLocaleDateString()} ${new Date(buckState.buck.UDT).toLocaleTimeString()}` : ''}</span>
                      </div>
                      <div>
                        <Box
                            sx={{
                              margin: 'auto',
                              width: '100%',
                              borderBottom: 'none',
                              marginTop: '-3px'
                            }}
                          >
                          <MatchIconComponent matchName={'IsUniqueDigits'} isTrue={buckState.buck.MAT.IsUniqueDigits}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsEuroDate'} isTrue={buckState.buck.MAT.IsEuroDate}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsDate'} isTrue={buckState.buck.MAT.IsDate}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsTwoDigits'} isTrue={buckState.buck.MAT.IsTwoDigits}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsRepeatingPairs4'} isTrue={buckState.buck.MAT.IsRepeatingPairs4}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsAllPairs2'} isTrue={buckState.buck.MAT.IsAllPairs2}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsPalindrome'} isTrue={buckState.buck.MAT.IsPalindrome}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsSixOrMoreSameDigit'} isTrue={buckState.buck.MAT.IsSixOrMoreSameDigit}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsRepeatingPairs2'} isTrue={buckState.buck.MAT.IsRepeatingPairs2}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsAllPairs4'} isTrue={buckState.buck.MAT.IsAllPairs4}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsConsectutivePairDescending'} isTrue={buckState.buck.MAT.IsConsectutivePairDescending}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsConsectutivePairAscending'} isTrue={buckState.buck.MAT.IsConsectutivePairAscending}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsOneDigit'} isTrue={buckState.buck.MAT.IsOneDigit}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsConsectutiveDescending'} isTrue={buckState.buck.MAT.IsConsectutiveDescending}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsConsectutiveAscending'} isTrue={buckState.buck.MAT.IsConsectutiveAscending}></MatchIconComponent>
                          <MatchIconComponent matchName={'IsStarNote'} isTrue={buckState.buck.MAT.IsStarNote}></MatchIconComponent>
                        </Box>
                      </div>
                    </div>
                    <textarea cols={170} rows={25} value={JSON.stringify(buckState.buck)} readOnly></textarea>
                  </>
                ) : (
                  <>
                    { !state.isLocalMode && buckState.bucks?.length > 0 ? (
                      <>
                        <div>
                          <h1>{buckState.queryTitle}: {buckState.bucks.length}</h1>
                          <div className='txt-md txt-italic'>
                              {buckState.queryDesc}
                          </div>
                        </div>
                        {
                          buckState.actionLoaded === ActionLoaded.searchWithMatchesSuccess || buckState.actionLoaded === ActionLoaded.searchTopHistorySuccess ? (
                            <div>
                              <SearchChartComponent actionLoaded={buckState.actionLoaded} bucks={buckState.bucks}></SearchChartComponent>
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )
                        }
                        <div className='m-t-lg'>
                          {buckList}
                        </div>
                      </>
                    ) : (
                      <>
                          <h1>nothing fetched yet</h1>
                          <div>
                            <div>C11111111M</div>
                            <div>B55555555*</div>
                            <div>D04480011A</div>
                          </div>
                      </>
                    )}
                  </>
                )}
              </>
          )}
      </ThemeProvider>
    </div>
  )
}

export default WebComponent;

