import { useDispatch, useSelector } from 'react-redux';
import styles from './sidebar.module.scss';
import { loadMostRecentValuable, loadAllValuable, loadAllImported, loadAllNotImported, doImport, loadBuck, searchSN, searchChangeMatchItem, searchWithMatches, searchTopHistory, searchChangeBuckItem, searchBucks, doExport, loadAllExported, loadAllNotExported } from '../../store/buck/buck.actions';
import { RootState } from '../../store/root.state';
import { ActionLoaded, BuckState } from '../../store/buck/buck.state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheck, faNoteSticky, faArrowRight, faBanSmoking, faChevronDown, faGlobeAmericas  } from '@fortawesome/free-solid-svg-icons';
import { Button, Checkbox, FormControl, FormControlLabel, IconButton, Input, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import MatchIconComponent from '../components/match-icon/match-icon.component';
import { toggleExpandAll, toggleSearchBucksIsExpanded, toggleSearchHistoryIsExpanded, toggleSearchSNIsExpanded, toggleSearchValuableMatchesIsExpanded } from '../../store/root.actions';
import React from 'react';

const SideBarComponent = ({ title }: any) => {
    const state = useSelector((state:any) => state.reducer as RootState);
    const buckState = useSelector((state:any) => state.buckReducer as BuckState);
    const dispatch = useDispatch();

    const handleClickMostRecent = async () => {
        dispatch(loadMostRecentValuable());
    }
    
    const handleClickAllValuable = async () => {
        dispatch(loadAllValuable());
    }
    const handleClickAllImported = async () => {
        dispatch(loadAllImported());
    }
    const handleClickAllNotImported = async () => {
        dispatch(loadAllNotImported());
    }
    const handleClickAllExported = async () => {
        dispatch(loadAllExported());
    }
    const handleClickAllNotExported = async () => {
        dispatch(loadAllNotExported());
    }
    
    const handleClickImport = async () => {
        dispatch(doImport());
    }
    const handleClickExport = async () => {
        dispatch(doExport());
    }
  
    const handleClickLoadBuck = async () => {
        dispatch(loadBuck(buckState.buckQuery.searchSN));
    }

    const handleClickLoadBucks = async () => {
        dispatch(searchBucks(['', buckState.buckQuery]));
    }
    
    const handleClickGetHistory = async () => {
        dispatch(searchTopHistory());
    }
    
    const handleChange = (e:any) => {
      dispatch(searchSN(e.target.value));
    }

    const handleCheckChange = (e:any) => {
        console.log("handleCheckChange", e);
        dispatch(searchChangeMatchItem([e.target.name, e.target.checked]));
    }
    
    const handleClickSearchWithMatches = async () => {
        console.log("buckState.buckQuery", buckState.buckQuery);
        dispatch(searchWithMatches(buckState.buckQuery.selectedMAT));
    }
    
    const handleToggleSearchSNIsExpanded = async () => {
        dispatch(toggleSearchSNIsExpanded());
    }

    const handleToggleSearchBucksIsExpanded = async () => {
        dispatch(toggleSearchBucksIsExpanded());
    }
    
    const handleToggleSearchHistoryIsExpanded = async () => {
        dispatch(toggleSearchHistoryIsExpanded());
    }
    
    const handleToggleSearchValuableMatchesIsExpanded = async () => {
        dispatch(toggleSearchValuableMatchesIsExpanded());
    }

    const handleToggleExpandAll = async () => {
        dispatch(toggleExpandAll());
    }
    
    const handleKeyDown = async (event:any) => {
        if(event.keyCode === 13){
            event.preventDefault();
            dispatch(loadBuck(buckState.buckQuery.searchSN));
        }
    }

    const handlePxChange = (e:any) =>{
        dispatch(searchChangeBuckItem(['PX', e.target.value]));
    }
    
    const handleSxChange = (e:any) =>{
        dispatch(searchChangeBuckItem(['SX', e.target.value]));
    }

    const handleSdChange = (e:any) =>{
        const reg = /[^[0-9]/g;
        const txt:string = e.target.value;
        const d = txt.replace(reg, '');
        dispatch(searchChangeBuckItem(['SD', d]));
    }

    const pxValues = ['','A','B','C','D','E','F','G','H','I','J','K','L'];
    const pxMenuItems = pxValues.map((v)=>
        <MenuItem key={v} value={v}>{v}</MenuItem>
    );
    const sxValues = ['','A','B','C','D','E','F','G','H','I','J','K','L','M','N','P','Q','R','S','T','U','V','W','X','Y','*'];
    const sxMenuItems = sxValues.map((v)=>
        <MenuItem key={v} value={v}>{v}</MenuItem>
    );
    
    
    return (
        <div className={styles['side-bar']}>
            <div className={styles['side-bar-container']}>
                {state.isLocalMode ? (
                    <div className={`${styles['side-bar-inner-container']} p-t-lg`}>
                        <div className={`${styles['title']} txt-xl`}>SQL APIs</div>
                        <ul className={styles['menu']}>
                            <li onClick={handleClickMostRecent} className={buckState.actionLoaded === ActionLoaded.loadMostRecentValuableSuccess ? `${styles['active']}` : ''}>
                                <FontAwesomeIcon icon={faMoneyCheck} className='m-r-md'/> Most Recent Valuable
                            </li>
                            <li onClick={handleClickAllValuable} className={buckState.actionLoaded === ActionLoaded.loadAllValuableSuccess ? `${styles['active']}` : ''}>
                                <FontAwesomeIcon icon={faMoneyCheck} className='m-r-md'/> All Valuable
                            </li>
                            <li onClick={handleClickAllNotImported} className={buckState.actionLoaded === ActionLoaded.loadAllNotImportedSuccess ? `${styles['active']}` : ''}>
                                <FontAwesomeIcon icon={faNoteSticky} className='m-r-md'/> All NOT Imported
                            </li>
                            <li onClick={handleClickAllExported} className={buckState.actionLoaded === ActionLoaded.loadAllExportedSuccess ? `${styles['active']}` : ''}>
                                <FontAwesomeIcon icon={faMoneyCheck} className='m-r-md'/> All Exported
                            </li>
                            <li onClick={handleClickAllNotExported} className={buckState.actionLoaded === ActionLoaded.loadAllNotExportedSuccess ? `${styles['active']}` : ''}>
                                <FontAwesomeIcon icon={faMoneyCheck} className='m-r-md'/> All NOT Exported
                            </li>
                            <li onClick={handleClickAllImported} className={`${styles['danger']} ${buckState.actionLoaded === ActionLoaded.loadAllImportedSuccess ? `${styles['active']}` : ''}`}>
                                <FontAwesomeIcon icon={faMoneyCheck} className='m-r-md'/> All Imported <FontAwesomeIcon icon={faBanSmoking} className='m-r-xsm'/><FontAwesomeIcon icon={faBanSmoking} className='m-r-xsm'/><FontAwesomeIcon icon={faBanSmoking} className='m-r-xsm'/>
                            </li>
                            <li onClick={handleClickImport} className='m-t-xl'>
                                <FontAwesomeIcon icon={faNoteSticky} className='m-r-xsm'/>
                                <FontAwesomeIcon icon={faArrowRight} className='m-r-xsm'/>
                                <FontAwesomeIcon icon={faMoneyCheck} className='m-r-md'/> Import
                            </li>
                            <li onClick={handleClickExport}>
                                <FontAwesomeIcon icon={faMoneyCheck} className='m-r-xsm'/>
                                <FontAwesomeIcon icon={faArrowRight} className='m-r-xsm'/>
                                <FontAwesomeIcon icon={faGlobeAmericas} className='m-r-md'/> Export
                            </li>
                        </ul>
                    </div>
                ) : (
                    <div className={`${styles['side-bar-inner-container']} p-t-sm p-b-xl`}>
                        <div className='display-flex flex-direction-row justify-content-sb align-items-c' onClick={handleToggleExpandAll}>
                            <div className={`${styles['title']} txt-xl w-100`}>MongoDb APIs</div>
                            <IconButton><FontAwesomeIcon icon={faChevronDown} color="white" className={state.sidebarState.allExpanded ? 'fa-rotate-180' : ''} /></IconButton>
                        </div>
                        <div className='m-b-xl w-100 p-l-md p-r-md m-t-sm'>
                            <form>
                                <div>
                                    <div className={styles['accHeader']} onClick={handleToggleSearchSNIsExpanded}>
                                        <div className='txt-lg'>Search SN</div>
                                        <IconButton><FontAwesomeIcon icon={faChevronDown} color="white" className={state.sidebarState.searchSNIsExpanded ? 'fa-rotate-180' : ''} /></IconButton>
                                    </div>
                                    <div className={`${styles['accBody']} ${state.sidebarState.searchSNIsExpanded  ? `${styles['expanded']}` : ''} p-t-sm`}>
                                        <div className='p-b-sm'>
                                            <Input type='text' name='txtSn' placeholder='SN' style={{width:'219px'}} value={buckState.buckQuery.searchSN} onChange={handleChange} onKeyDown={handleKeyDown} />
                                        </div>
                                        <Button variant="outlined" fullWidth={true} onClick={handleClickLoadBuck}>Get Buck</Button>
                                    </div>
                                </div>
                                <div>
                                    <div className={styles['accHeader']} onClick={handleToggleSearchBucksIsExpanded}>
                                        <div className='txt-lg'>Search Bucks</div>
                                        <IconButton><FontAwesomeIcon icon={faChevronDown} color="white" className={state.sidebarState.searchBucksIsExpanded ? 'fa-rotate-180' : ''} /></IconButton>
                                    </div>
                                    <div className={`${styles['accBody']} ${state.sidebarState.searchBucksIsExpanded  ? `${styles['expanded']}` : ''} p-t-md`}>
                                        <div className='txt-xsm'>&nbsp;</div>
                                        <FormControl fullWidth>
                                            <InputLabel id="label-px">PX</InputLabel>
                                            <Select
                                                labelId="label-px"
                                                id="select-px"
                                                value={buckState.buckQuery.searchBuck?.PX}
                                                label="PX"
                                                className='m-b-sm'
                                                onChange={handlePxChange}
                                            >
                                                {pxMenuItems}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <InputLabel id="label-sx">SX</InputLabel>
                                            <Select
                                                labelId="label-sx"
                                                id="select-sx"
                                                value={buckState.buckQuery.searchBuck?.SX}
                                                label="SX"
                                                className='m-b-sm'
                                                onChange={handleSxChange}
                                            >
                                                {sxMenuItems}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="txt-sd"
                                                variant="outlined"
                                                label="SD"
                                                value={buckState.buckQuery.searchBuck?.SD}
                                                onChange={handleSdChange}
                                            />
                                            <div>&nbsp;</div>
                                        </FormControl>
                                        <Button variant="outlined" fullWidth={true} onClick={handleClickLoadBucks}>Get Bucks</Button>
                                    </div>
                                </div>
                                <div>
                                    <div className={styles['accHeader']} onClick={handleToggleSearchHistoryIsExpanded}>
                                        <div className='txt-lg'>Top History</div>
                                        <IconButton><FontAwesomeIcon icon={faChevronDown} color="white" className={state.sidebarState.searchHistoryIsExpanded ? 'fa-rotate-180' : ''} /></IconButton>
                                    </div>
                                    <div className={`${styles['accBody']} ${state.sidebarState.searchHistoryIsExpanded  ? `${styles['expanded']}` : ''} p-t-sm`}>
                                        <Button variant="outlined" fullWidth={true} onClick={handleClickGetHistory}>Get Top History</Button>
                                    </div>
                                </div>
                                <div>
                                    <div className={styles['accHeader']} onClick={handleToggleSearchValuableMatchesIsExpanded}>
                                        <div className='txt-lg'>Valuable by Match</div>
                                        <IconButton><FontAwesomeIcon icon={faChevronDown} color="white" className={state.sidebarState.searchValuableMatchesIsExpanded ? 'fa-rotate-180' : ''} /></IconButton>
                                    </div>
                                    <div className={`${styles['accBody']} ${state.sidebarState.searchValuableMatchesIsExpanded  ? `${styles['expanded']}` : ''}`}>
                                        <div>
                                            <ul className={styles['checkbox-list']}>
                                                <li>
                                                    <MatchIconComponent matchName={'IsUniqueDigits'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsUniqueDigits} name='IsUniqueDigits' onChange={handleCheckChange} />} label="IsUniqueDigits" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsEuroDate'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsEuroDate} name='IsEuroDate' onChange={handleCheckChange} />} label="IsEuroDate" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsDate'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsDate} name='IsDate' onChange={handleCheckChange} />} label="IsDate" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsTwoDigits'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsTwoDigits} name='IsTwoDigits' onChange={handleCheckChange} />} label="IsTwoDigits" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsRepeatingPairs4'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsRepeatingPairs4} name='IsRepeatingPairs4' onChange={handleCheckChange} />} label="IsRepeatingPairs4" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsAllPairs2'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsAllPairs2} name='IsAllPairs2' onChange={handleCheckChange} />} label="IsAllPairs2" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsPalindrome'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsPalindrome} name='IsPalindrome' onChange={handleCheckChange} />} label="IsPalindrome" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsSixOrMoreSameDigit'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsSixOrMoreSameDigit} name='IsSixOrMoreSameDigit' onChange={handleCheckChange} />} label="IsSix+SameDigit" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsRepeatingPairs2'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsRepeatingPairs2} name='IsRepeatingPairs2' onChange={handleCheckChange} />} label="IsRepeatingPairs2" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsAllPairs4'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsAllPairs4} name='IsAllPairs4' onChange={handleCheckChange} />} label="IsAllPairs4" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsConsectutivePairDescending'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsConsectutivePairDescending} name='IsConsectutivePairDescending' onChange={handleCheckChange} />} label="IsConsPairDesc" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsConsectutivePairAscending'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsConsectutivePairAscending} name='IsConsectutivePairAscending' onChange={handleCheckChange} />} label="IsConsPairAsc" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsOneDigit'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsOneDigit} name='IsOneDigit' onChange={handleCheckChange} />} label="IsOneDigit" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsConsectutiveDescending'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsConsectutiveDescending} name='IsConsectutiveDescending' onChange={handleCheckChange} />} label="IsConsDesc" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsConsectutiveAscending'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsConsectutiveAscending} name='IsConsectutiveAscending' onChange={handleCheckChange} />} label="IsConsAsc" />
                                                </li>
                                                <li>
                                                    <MatchIconComponent matchName={'IsStarNote'} isTrue={true}></MatchIconComponent>
                                                    <FormControlLabel control={<Checkbox checked={buckState.buckQuery.selectedMAT?.IsStarNote} name='IsStarNote' onChange={handleCheckChange} />} label="IsStarNote" />
                                                </li>
                                                <li className='m-t-sm txt-align-c'>
                                                    <Button variant="outlined" onClick={handleClickSearchWithMatches}>Search Matches</Button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SideBarComponent;