import { createAction, nanoid } from '@reduxjs/toolkit'
import { IMatch } from './buck.state';

export const LoadAllImported = 'LoadAllImported';
export const LoadAllImportedSuccess = 'LoadAllImportedSuccess';
export const LoadAllImportedFailed = 'LoadAllImportedFailed';

export const LoadAllExported = 'LoadAllExported';
export const LoadAllExportedSuccess = 'LoadAllExportedSuccess';
export const LoadAllExportedFailed = 'LoadAllExportedFailed';

export const LoadAllNotImported = 'LoadAllNotImported';
export const LoadAllNotImportedSuccess = 'LoadAllNotImportedSuccess';
export const LoadAllNotImportedFailed = 'LoadAllNotImportedFailed';

export const LoadAllNotExported = 'LoadAllNotExported';
export const LoadAllNotExportedSuccess = 'LoadAllNotExportedSuccess';
export const LoadAllNotExportedFailed = 'LoadAllNotExportedFailed';

export const LoadMostRecentValuable = 'LoadMostRecentValuable';
export const LoadMostRecentValuableSuccess = 'LoadMostRecentValuableSuccess';
export const LoadMostRecentValuableFailed = 'LoadMostRecentValuableFailed';

export const LoadAllValuable = 'LoadAllValuable';
export const LoadAllValuableSuccess = 'LoadAllValuableSuccess';
export const LoadAllValuableFailed = 'LoadAllValuableFailed';

export const DoImport = 'DoImport';
export const DoExport = 'DoExport';

export const IsFetching = 'IsFetching';
export const ClearBucks = 'ClearBucks';

export const loadAllImported = createAction(LoadAllImported);
export const loadAllImportedSuccess = createAction<any>(LoadAllImportedSuccess);
export const loadAllImportedFailed = createAction(LoadAllImportedFailed);

export const loadAllExported = createAction(LoadAllExported);
export const loadAllExportedSuccess = createAction<any>(LoadAllExportedSuccess);
export const loadAllExportedFailed = createAction(LoadAllExportedFailed);

export const loadAllNotImported = createAction(LoadAllNotImported);
export const loadAllNotImportedSuccess = createAction<any>(LoadAllNotImportedSuccess);
export const loadAllNotImportedFailed = createAction(LoadAllNotImportedFailed);

export const loadAllNotExported = createAction(LoadAllNotExported);
export const loadAllNotExportedSuccess = createAction<any>(LoadAllNotExportedSuccess);
export const loadAllNotExportedFailed = createAction(LoadAllNotExportedFailed);

export const loadMostRecentValuable = createAction(LoadMostRecentValuable);
export const loadMostRecentValuableSuccess = createAction<any>(LoadMostRecentValuableSuccess);
export const loadMostRecentValuableFailed = createAction(LoadMostRecentValuableFailed);

export const loadAllValuable = createAction(LoadAllValuable);
export const loadAllValuableSuccess = createAction<any>(LoadAllValuableSuccess);
export const loadAllValuableFailed = createAction(LoadAllValuableFailed);

export const doImport = createAction(DoImport);
export const doExport = createAction(DoExport);

export const isFetching = createAction(IsFetching);
export const clearBucks = createAction(ClearBucks);


//web actions
export const LoadBuck = 'LoadBuck';
export const LoadBuckSuccess = 'LoadBuckSuccess';
export const LoadBuckFailed = 'LoadBuckFailed';
export const SearchSN = 'SearchSN';
export const SearchChangeMatchItem = 'SearchChangeMatchItem';
export const SearchChangeBuckItem = 'SearchChangeBuckItem';

export const SearchWithMatches = 'SearchWithMatches';
export const SearchWithMatchesSuccess = 'SearchWithMatchesSuccess';
export const SearchWithMatchesFailed = 'SearchWithMatchesFailed';

export const SearchTopHistory = 'SearchTopHistory';
export const SearchTopHistorySuccess = 'SearchTopHistorySuccess';
export const SearchTopHistoryFailed = 'SearchTopHistoryFailed';

export const SearchBucks = 'SearchBucks';
export const SearchBucksSuccess = 'SearchBucksSuccess';
export const SearchBucksFailed = 'SearchBucksFailed';

export const LoadDashboardDistrictCounts = 'LoadDashboardDistrictCounts';
export const LoadDashboardDistrictCountsSuccess = 'LoadDashboardDistrictCountsSuccess';
export const LoadDashboardDistrictCountsFailed = 'LoadDashboardDistrictCountsFailed';

export const LoadDashboardTopHistoryChartData = 'LoadDashboardTopHistoryChartData';
export const LoadDashboardTopHistoryChartDataSuccess = 'LoadDashboardTopHistoryChartDataSuccess';
export const LoadDashboardTopHistoryChartDataFailed = 'LoadDashboardTopHistoryChartDataFailed';

export const LoadDashboardTopRatedChartData = 'LoadDashboardTopRatedChartData';
export const LoadDashboardTopRatedChartDataSuccess = 'LoadDashboardTopRatedChartDataSuccess';
export const LoadDashboardTopRatedChartDataFailed = 'LoadDashboardTopRatedChartDataFailed';

export const loadBuck = createAction<string>(LoadBuck);
export const loadBuckSuccess = createAction<any>(LoadBuckSuccess);
export const loadBuckFailed = createAction(LoadBuckFailed);

export const searchChangeMatchItem = createAction<any, any>(SearchChangeMatchItem);
export const searchChangeBuckItem = createAction<any, any>(SearchChangeBuckItem);

export const searchWithMatches = createAction<IMatch>(SearchWithMatches);
export const searchWithMatchesSuccess = createAction<any>(SearchWithMatchesSuccess);
export const searchWithMatchesFailed = createAction(SearchWithMatchesFailed);

export const searchTopHistory = createAction(SearchTopHistory);
export const searchTopHistorySuccess = createAction<any>(SearchTopHistorySuccess);
export const searchTopHistoryFailed = createAction(SearchTopHistoryFailed);

export const searchBucks = createAction<any, any>(SearchBucks);
export const searchBucksSuccess = createAction<any>(SearchBucksSuccess);
export const searchBucksFailed = createAction(SearchBucksFailed);

export const loadDashboardDistrictCounts = createAction(LoadDashboardDistrictCounts);
export const loadDashboardDistrictCountsSuccess = createAction<any>(LoadDashboardDistrictCountsSuccess);
export const loadDashboardDistrictCountsFailed = createAction(LoadDashboardDistrictCountsFailed);

export const loadDashboardTopHistoryChartData = createAction(LoadDashboardTopHistoryChartData);
export const loadDashboardTopHistoryChartDataSuccess = createAction<any>(LoadDashboardTopHistoryChartDataSuccess);
export const loadDashboardTopHistoryChartDataFailed = createAction(LoadDashboardTopHistoryChartDataFailed);

export const loadDashboardTopRatedChartData = createAction(LoadDashboardTopRatedChartData);
export const loadDashboardTopRatedChartDataSuccess = createAction<any>(LoadDashboardTopRatedChartDataSuccess);
export const loadDashboardTopRatedChartDataFailed = createAction(LoadDashboardTopRatedChartDataFailed);

export const searchSN = createAction<string>(SearchSN);


