import { clearBucks, doExport, doImport, isFetching, loadAllExported, loadAllExportedSuccess, loadAllImported, loadAllImportedSuccess, loadAllNotExported, loadAllNotExportedSuccess, loadAllNotImported, loadAllNotImportedSuccess, loadAllValuable, loadAllValuableSuccess, loadBuck, loadBuckSuccess, loadDashboardDistrictCounts, loadDashboardDistrictCountsSuccess, loadDashboardTopRatedChartData, loadDashboardTopRatedChartDataSuccess, loadDashboardTopHistoryChartData, loadDashboardTopHistoryChartDataSuccess, loadMostRecentValuable, loadMostRecentValuableSuccess, searchBucks, searchBucksSuccess, searchChangeBuckItem, searchChangeMatchItem, searchSN, searchTopHistory, searchTopHistorySuccess, searchWithMatches, searchWithMatchesSuccess } from "./buck.actions";
import { ActionLoaded, BuckState, initialState } from "./buck.state";

const buckReducer = (state:BuckState = initialState, action:any) => {
    console.log(action);
    switch (action.type) {
        case loadAllImported.type :
            return {
                ...state,
                showBucks: true,
                isFetching: true,
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            };
        case loadAllImportedSuccess.type :
            return {
                ...state,
                showBucks: true,
                bucks: action.payload,
                notes: [],
                queryTitle: 'All Imported',
                queryDesc: 'All Imported',
                nowShowing: 'loadAllImportedSuccess',
                actionLoaded: ActionLoaded.loadAllImportedSuccess,
                isFetching: false,
            };
        case loadAllNotImported.type :
            return {
                ...state,
                isFetching: true,
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            };
        case loadAllNotImportedSuccess.type :
            return {
                ...state,
                showBucks: false,
                notes: action.payload,
                bucks: [],
                queryTitle: 'All Not Imported',
                queryDesc: 'All Not Imported',
                nowShowing: 'loadAllNotImportedSuccess',
                actionLoaded: ActionLoaded.loadAllNotImportedSuccess,
                isFetching: false,
            };
        case loadAllExported.type :
            return {
                ...state,
                showBucks: true,
                isFetching: true,
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            };
        case loadAllExportedSuccess.type :
            return {
                ...state,
                showBucks: true,
                bucks: action.payload,
                notes: [],
                queryTitle: 'All Exported',
                queryDesc: 'All Exported',
                nowShowing: 'loadAllExportedSuccess',
                actionLoaded: ActionLoaded.loadAllExportedSuccess,
                isFetching: false,
            };
        case loadAllNotExported.type :
            return {
                ...state,
                isFetching: true,
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            };
        case loadAllNotExportedSuccess.type :
            return {
                ...state,
                showBucks: true,
                bucks: action.payload,
                notes: [],
                queryTitle: 'All Not Exported',
                queryDesc: 'All Not Exported',
                nowShowing: 'loadAllNotExportedSuccess',
                actionLoaded: ActionLoaded.loadAllNotExportedSuccess,
                isFetching: false,
            };
        case loadAllValuable.type:
            return {
                ...state,
                isFetching: true,
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            }
        case loadAllValuableSuccess.type:
            return {
                ...state,
                bucks: action.payload,
                notes: [],
                queryTitle: 'All Valuable',
                queryDesc: 'All Valuable',
                nowShowing: 'loadAllValuableSuccess',
                actionLoaded: ActionLoaded.loadAllValuableSuccess,
                showBucks: true,
                isFetching: false,
            }
        case loadMostRecentValuable.type:
            return {
                ...state,
                isFetching: true,
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            }
        case loadMostRecentValuableSuccess.type:
            return {
                ...state,
                bucks: action.payload,
                notes: [],
                queryTitle: 'Most Recent Valuable',
                queryDesc: 'Most Recent Valuable',
                nowShowing: 'loadMostRecentValuableSuccess',
                actionLoaded: ActionLoaded.loadMostRecentValuableSuccess,
                showBucks: true,
                isFetching: false,
            }
        case isFetching.type:
            return {
                ...state,
                isFetching: true,
            }
        case doImport.type:
            return {
                ...state,
                isFetching: true,
            }
        case doExport.type:
            return {
                ...state,
                isFetching: false,
            }
        case loadBuck.type:
            return {
                ...state,
                queryTitle: `Getting Buck: ${action.payload}`,
                isFetching: true,
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            }
        case loadBuckSuccess.type:
            return {
                ...state,
                bucks: [],
                notes: [],
                queryTitle: `Search SN`,
                queryDesc: "this is the BUCK you've been looking for",
                nowShowing: 'loadBuckSuccess',
                actionLoaded: ActionLoaded.loadBuckSuccess,
                showBucks: true,
                isFetching: false,
                buck: action.payload,
            }
        case searchBucks.type:
            return {
                ...state,
                queryTitle: `Getting Buck: ${action.payload}`,
                isFetching: true,
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            }
        case searchBucksSuccess.type:
            return {
                ...state,
                bucks: action.payload,
                notes: [],
                queryTitle: `Search Bucks`,
                queryDesc: "these is the BUCKs you've been looking for",
                nowShowing: 'searchBucksSuccess',
                actionLoaded: ActionLoaded.searchBucksSuccess,
                showBucks: true,
                isFetching: false,
                buck: initialState.buck,
            }
        case searchSN.type:
            return {
                ...state,
                buckQuery: {...state.buckQuery, searchSN: action.payload}
            }
        case searchChangeMatchItem.type:
            console.log("state", state);
            let newSelectedMatch = JSON.parse(JSON.stringify(state.buckQuery.selectedMAT));
            newSelectedMatch[action.payload[0]] = action.payload[1];
            console.log("newSelectedMatch", newSelectedMatch);
            return {
                ...state,
                buckQuery: {...state.buckQuery, selectedMAT: newSelectedMatch}
            }
        case searchWithMatches.type:
            return {
                ...state,
                isFetching: true,
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            }
        case searchWithMatchesSuccess.type:
            return {
                ...state,
                bucks: action.payload,
                notes: [],
                buck: initialState.buck,
                queryTitle: 'Search Matches',
                queryDesc: "searched the highest rated bucks using the match criteria like: isPalindrome",
                nowShowing: 'searchWithMatchesSuccess',
                actionLoaded: ActionLoaded.searchWithMatchesSuccess,
                showBucks: true,
                isFetching: false,
            }
        case searchTopHistory.type:
            return {
                ...state,
                isFetching: true,
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            }
        case searchTopHistorySuccess.type:
            return {
                ...state,
                bucks: action.payload,
                notes: [],
                buck: initialState.buck,
                queryTitle: 'Top History',
                queryDesc: 'bucks with the most location traces... well travelled!!!',
                nowShowing: 'searchTopHistorySuccess',
                actionLoaded: ActionLoaded.searchTopHistorySuccess,
                showBucks: true,
                isFetching: false,
            }
        case searchChangeBuckItem.type:
            let newSearchBuck = JSON.parse(JSON.stringify(state.buckQuery.searchBuck));
            newSearchBuck[action.payload[0]] = action.payload[1];
            console.log("newSearchBuck", newSearchBuck);
            return {
                ...state,
                buckQuery: {...state.buckQuery, searchBuck: newSearchBuck}
            }
        case clearBucks.type:
            return {
                ...state,
                notes: [],
                bucks: [],
                buck: initialState.buck,
                isFetching: false,
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            }
        case loadDashboardDistrictCounts.type:
            return {
                ...state,
                dashboard: {...state.dashboard, isDistrictDataFetching: true},
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            }
        case loadDashboardDistrictCountsSuccess.type:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard, 
                    isDistrictDataFetching: false, 
                    districtChartData: action.payload,
                },
            }
        case loadDashboardTopHistoryChartData.type:
            return {
                ...state,
                dashboard: {...state.dashboard, isTopHistoryChartDataFetching: true},
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            }
        case loadDashboardTopHistoryChartDataSuccess.type:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard, 
                    topHistoryChartData: action.payload, 
                    isTopHistoryChartDataFetching: false,
                },
            }
        case loadDashboardTopRatedChartData.type:
            return {
                ...state,
                dashboard: {...state.dashboard, topRatedChartDataFetching: true},
                nowShowing: '',
                actionLoaded: ActionLoaded.none,
            }
        case loadDashboardTopRatedChartDataSuccess.type:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard, 
                    topRatedChartData: action.payload, 
                    topRatedChartDataFetching: false,
                },
            }
            
        default:
            return state;
    }
};

export default buckReducer;