export const initialSidebarState: SidebarState = {
    searchSNIsExpanded: false,
    searchBucksIsExpanded: false,
    searchHistoryIsExpanded: true,
    searchValuableMatchesIsExpanded: false,
    allExpanded: false,
}

export class RootState {
    hasError: boolean = false;
    error: string = '';
    isDashboard: boolean = true;
    isLocalMode: boolean = true;
    modeName: string = '';
    sidebarState: SidebarState = initialSidebarState;
    isRunningOnLocalHost: boolean = true;
}

export class SidebarState{
    searchSNIsExpanded: boolean = false;
    searchBucksIsExpanded: boolean = false;
    searchHistoryIsExpanded: boolean = false;
    searchValuableMatchesIsExpanded: boolean = true;
    allExpanded: boolean = true;
}

