import { useSelector, useDispatch } from 'react-redux';
import styles from './header.module.scss';
import React, { useEffect } from 'react';
import { RootState } from '../../store/root.state';
import { changeToWebMode, checkIsRunningOnLocalhost, gotoDashboard } from '../../store/root.actions';

const HeaderComponent = ({ title }: any) => {
    const state = useSelector((state:any) => state.reducer as RootState);
    const dispatch = useDispatch();

    const setActiveMode = (isWebMode:boolean) => {
        dispatch(changeToWebMode(isWebMode));
    }
    const handleHomeClick = () => {
        dispatch(gotoDashboard());
    }

    useEffect(()=>{
        dispatch(checkIsRunningOnLocalhost());
    }, [dispatch]);

    return (
        <div className={`${styles['header-container']} ${state.isDashboard ? styles['dashboard'] : ''}`}>
            <div className={`${styles['logo-panel']} ${state.isDashboard ? styles["active"] : ''}`} onClick={handleHomeClick}>
                <img src={'../../images/bucktrace_logo_white.svg'} alt='logo' />
                <span>HOME</span>
            </div>
            <div className={styles['right-panel']}>
                <div className={styles["info-panel"]}>
                    {state.isDashboard ? (
                        <h1>admin.bucktrace.com</h1>
                    ) : (
                        <div>
                            You are now using ({state.modeName} only) APIs
                        </div>
                    )}
                </div>
                <div className={styles["nav-panel"]}>
                    <div onClick={()=>setActiveMode(false)} className={(!state.isDashboard && state.isLocalMode ? styles["active"] : '')}>
                        <img src={'../../images/desktop_icon_white.png'}  alt='desktop icon' />
                        <span>
                            LOCAL
                        </span>
                    </div>
                    <div onClick={()=>setActiveMode(true)} className={(!state.isDashboard && !state.isLocalMode ? styles["active"] : '')}>
                        <img src={'../../images/cloud_icon_white.png'} alt='cloud icon' />
                        <span>
                            WEB
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderComponent;

//<img src={'../../images/bucktrace_logo_white.svg'} style={{width:'50px'}} />