import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { memo } from 'react';
  import { Bar } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );
  
const getChartData = (chartBucks:any[], isTopHistoryChartDataFetching:boolean) => {
    let options: any;
    let rtn: any;
    const chartLabelColor = '#efefef';

    options = {
      indexAxis: 'y' as const,
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend:{
          display: false,
        },
        title: {
          display: true,
          text: 'Max Number of Traces by Buck',
          color: chartLabelColor,
          font: {
            size: 24
          }
        },
      },
      scales: {
        y: {
          ticks: {
            color: chartLabelColor,
            // font: {
            //   size: 24,
            // },
          }
        },
        x: {
          ticks: {
            color: chartLabelColor,
          }
        },
      },
    };

    rtn = {
      labels: chartBucks?.map(b=>b.SN),
      datasets: [
        {
          label: '',
          data: chartBucks?.map(b=>b.traceCount), 
          backgroundColor: [
            '#234227',
          ],
          datalabels: {
            color: '#fff',
          },
        },
      ],
    }
    console.log("rtn",rtn);
    return <Bar options={options} data={rtn} />;
  }
  
const TopHistoryChartComponent = ({ topHistoryChartData, isTopHistoryChartDataFetching }: any) => {
   console.log("topHistoryChartData",topHistoryChartData);
    return ( 
      <>
      { isTopHistoryChartDataFetching ? (
        <img src='../images/loading.gif' alt='loading...' width="55px" />
      ) : (
        <>
          {getChartData(topHistoryChartData, isTopHistoryChartDataFetching)}
        </>
      )}
    </>
    )
}

export default memo(TopHistoryChartComponent);