import { Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { IBuckQuery } from '../store/buck/buck.state';

const ROOT_URL = 'https://2yz450jyga.execute-api.us-east-2.amazonaws.com/latest/w';
export const fetchMostRecentValuable = () => {
    return ajax.getJSON(`http://localhost:55555/l/buck/most-recent-valuable`);
}

export const fetchAllValuable = () => {
    return ajax.getJSON(`http://localhost:55555/l/buck/valuable`);
}

export const fetchAllNotImported = () => {
    return ajax.getJSON(`http://localhost:55555/l/note/not-imported`);
}

export const fetchAllImported = () => {
    return ajax.getJSON(`http://localhost:55555/l/buck`);
}

export const fetchAllExported = () => {
    return ajax.getJSON(`http://localhost:55555/l/buck/exported`);
}

export const fetchAllNotExported = () => {
    return ajax.getJSON(`http://localhost:55555/l/buck/not-exported`);
}

export const addNotesToBuckTable = () => {
    return ajax.getJSON(`http://localhost:55555/l/note/import`);
}

export const addBucksSqlToBucksMongo = () => {
    return ajax.getJSON(`http://localhost:55555/l/buck/export`);
}


//web api's
export const fetchBuck = (sn:string) => {
    if(sn.length === 10){
        return ajax.getJSON(`${ROOT_URL}/buck/${sn}`);
    } else{
        return of(null);
    }
}

export const fetchHighestRatedBucksFromMatches = (selectedMAT:any): Observable<any> => {
    let matchString = ''
    let matches = [];
    for (const property in selectedMAT) {
        if(selectedMAT[property] === true){
            matches.push(property);
        }
    }
    matchString = matches.join(",");
    
    let requestBody:any = {
        searchField: matchString
    };
    if(matchString === ''){
        requestBody = {
            searchField: 'RatingValue',
            searchTerm: 5
        };
    }
    console.log("requestBody", requestBody);
    return ajax.post(`${ROOT_URL}/bucks/top-rated`, requestBody);
}

export const fetchBucks = (serialNumberList:string, query:IBuckQuery): Observable<any> => {
    let fields = [];
    let terms = [];
    let rb = {};
    if(query.searchBuck){
        if(query.searchBuck.PX){
            fields.push('PX');
            terms.push(query.searchBuck.PX);
        }
        if(query.searchBuck.SX){
            fields.push('SX');
            terms.push(query.searchBuck.SX);
        }
        if(query.searchBuck.SD){
            fields.push('SD');
            terms.push(query.searchBuck.SD);
        }
    }
    if(fields.length > 0){
        let requestBody = {
            searchField: '',
            searchTerm: '',
        };
        requestBody.searchField = fields.join(',');
        requestBody.searchTerm = terms.join(',');
        rb = requestBody;
    } else if(serialNumberList && serialNumberList.length >= 10){
        let requestBody = {
            serialNumberList: ''
        };
        requestBody.serialNumberList = serialNumberList;
        rb = requestBody;
    }

    console.log("requestBody", rb);
    return ajax.post(`${ROOT_URL}/bucks`, rb);
}

export const fetchTopHistory = () => {
    return ajax.getJSON(`${ROOT_URL}/bucks/top-history`);
}

export const fetchDashboardDistrictCounts = () => {
    return ajax.getJSON(`${ROOT_URL}/dashboard/district-counts`);
}

export const fetchDashboardTopHistoryChartData = () => {
    return ajax.getJSON(`${ROOT_URL}/dashboard/top-history`);
}

export const fetchDashboardTopRatedChartData = () => {
    return ajax.getJSON(`${ROOT_URL}/dashboard/top-rated`);
}

