import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
import { ActionLoaded } from '../../../store/buck/buck.state';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );

const getValuableChartData:any = (bucks:any[]) => {
  const IsUniqueDigits = bucks.filter(b=>b.MAT.IsUniqueDigits === true).length;
  const IsEuroDate = bucks.filter(b=>b.MAT.IsEuroDate === true).length;
  const IsDate = bucks.filter(b=>b.MAT.IsDate === true).length;
  const IsTwoDigits = bucks.filter(b=>b.MAT.IsTwoDigits === true).length;
  const IsRepeatingPairs4 = bucks.filter(b=>b.MAT.IsRepeatingPairs4 === true).length;
  const IsAllPairs2 = bucks.filter(b=>b.MAT.IsAllPairs2 === true).length;
  const IsPalindrome = bucks.filter(b=>b.MAT.IsPalindrome === true).length;
  const IsSixOrMoreSameDigit = bucks.filter(b=>b.MAT.IsSixOrMoreSameDigit === true).length;
  const IsRepeatingPairs2 = bucks.filter(b=>b.MAT.IsRepeatingPairs2 === true).length;
  const IsAllPairs4 = bucks.filter(b=>b.MAT.IsAllPairs4 === true).length;
  const IsConsectutivePairDescending = bucks.filter(b=>b.MAT.IsConsectutivePairDescending === true).length;
  const IsConsectutivePairAscending = bucks.filter(b=>b.MAT.IsConsectutivePairAscending === true).length;
  const IsOneDigit = bucks.filter(b=>b.MAT.IsOneDigit === true).length;
  const IsConsectutiveDescending = bucks.filter(b=>b.MAT.IsConsectutiveDescending === true).length;
  const IsConsectutiveAscending = bucks.filter(b=>b.MAT.IsConsectutiveAscending === true).length;
  const IsStarNote = bucks.filter(b=>b.MAT.IsStarNote === true).length;

  let rtn = [
    { name: 'IsUniqueDigits', value: IsUniqueDigits },
    { name: 'IsEuroDate', value: IsEuroDate },
    { name: 'IsDate', value: IsDate },
    { name: 'IsTwoDigits', value: IsTwoDigits },
    { name: 'IsRepeatingPairs4', value: IsRepeatingPairs4 },
    { name: 'IsAllPairs2', value: IsAllPairs2 },
    { name: 'IsPalindrome', value: IsPalindrome },
    { name: 'IsSixOrMoreSameDigit', value: IsSixOrMoreSameDigit },
    { name: 'IsRepeatingPairs2', value: IsRepeatingPairs2 },
    { name: 'IsAllPairs4', value: IsAllPairs4 },
    { name: 'IsConsectutivePairDescending', value: IsConsectutivePairDescending },
    { name: 'IsConsectutivePairAscending', value: IsConsectutivePairAscending },
    { name: 'IsOneDigit', value: IsOneDigit },
    { name: 'IsConsectutiveDescending', value: IsConsectutiveDescending },
    { name: 'IsConsectutiveAscending', value: IsConsectutiveAscending },
    { name: 'IsStarNote', value: IsStarNote },
  ]
  console.log("getValuableChartData", rtn);
  return rtn;
}

const getHistoryChartData:any = (bucks:any[]) => {
    const rtn = bucks.map((b)=>{
        return {name: b._id, value: b.HIST.length}
    });

    console.log("getHistoryChartData", rtn);
    return rtn;
}

const getChartData = (actionLoaded:ActionLoaded, bucks:any[]) => {
    let options: any;
    let rtn: any;
    const chartLabelColor = '#efefef';
    let chartBucks:any[] = [];
console.log("bucks", bucks);
  
    if (actionLoaded === ActionLoaded.searchWithMatchesSuccess) {
        chartBucks = getValuableChartData(bucks);
        options = {
            indexAxis: 'y' as const,
            elements: {
            bar: {
                borderWidth: 2,
            },
            },
            responsive: true,
            plugins: {
            legend:{
                display: false,
            },
            title: {
                display: true,
                text: 'Number of Valuable Matches by Type',
                color: chartLabelColor,
                font: {
                size: 24
                }
            },
            },
            scales: {
            y: {
                ticks: {
                color: chartLabelColor,
                // font: {
                //   size: 24,
                // },
                }
            },
            x: {
                ticks: {
                color: chartLabelColor,
                }
            },
            },
        };
        rtn = {
            labels: chartBucks.map(b=>b.name),
            datasets: [
                {
                    label: '',
                    data: chartBucks.map(b=>b.value),
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    color: "red",
                    borderWidth: 1,
                    hoverBackgroundColor: "peru",
                    hoverBorderColor: "peru",
                },
            ],
        }
    } else if (actionLoaded === ActionLoaded.searchTopHistorySuccess) {
      chartBucks = getHistoryChartData(bucks);
      options = {
        indexAxis: 'y' as const,
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend:{
            display: false,
          },
          title: {
            display: true,
            text: 'Max Number of Traces by Buck',
            color: chartLabelColor,
            font: {
              size: 24
            }
          },
        },
        scales: {
          y: {
            ticks: {
              color: chartLabelColor,
              // font: {
              //   size: 24,
              // },
            }
          },
          x: {
            ticks: {
              color: chartLabelColor,
            }
          },
        },
      };

      rtn = {
        labels: chartBucks.map(b=>b.name),
        datasets: [
          {
            label: '',
            data: chartBucks.map(b=>b.value),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            color: "red",
            borderWidth: 1,
            hoverBackgroundColor: "peru",
            hoverBorderColor: "peru",
          },
        ],
      }
    }
  
    console.log("actionLoaded",actionLoaded);
    console.log("rtn",rtn);
    return <Bar options={options} data={rtn} />;
  }
  
  
export const SearchChartComponent = ({ actionLoaded, bucks }: any) => {
    return ( 
        <>
            {getChartData(actionLoaded, bucks)}
        </>
    )
}