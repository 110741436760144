import './common.scss';
import styles from './app.module.scss';
import HeaderComponent from "./pages/header/header.component";
import SideBarComponent from './pages/sidebar/sidebar.component';
import MainComponent from './pages/main/main.component';
import { createTheme, ThemeProvider } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './store/root.state';

export default function App() {
    const state = useSelector((state:any) => state.reducer as RootState);
    const tableTheme = useMemo(
        () =>
          createTheme({
            palette: {
              mode: 'dark', //let's use the same dark/light mode as the global theme
              background: {
                default: '#0f1b5e',
                paper: '#192461',
              },
            },
          }),
        [],
      );

    return (
        <div className={styles['app-container']}>
            <div className={styles['top-panel']}>
                <HeaderComponent title={'HeaderComponent'} />
            </div>
            <ThemeProvider theme={tableTheme}>
                <div className={styles['bottom-panel']}>
                    <div className={styles['side-panel']} style={ state.isDashboard ? {display: 'none'} : {display: 'block'}}>
                        <SideBarComponent title={'SideBarComponent'} />
                    </div>
                    <div className={styles['main-panel']}>
                        <MainComponent title={'MainComponent'} />
                    </div>
                </div>
            </ThemeProvider>
        </div>
    );
}