import styles from './main.module.scss';
import { useSelector } from 'react-redux'
import { RootState } from '../../store/root.state';
import LocalComponent from './local/local.component';
import WebComponent from './web/web.component';
import DashboardComponent from '../dashboard/dashboard.component';

const MainComponent = ({ title }: any) => {
    const state = useSelector((state:any) => state.reducer as RootState);

    return (
        <div className={`${styles['main-container']}`}>
            {state.isDashboard ? (
              <DashboardComponent title={'DashboardComponent'}></DashboardComponent>
            ) : (
              <>
                {state.isLocalMode ? (
                  <LocalComponent title={'LocalComponent'}></LocalComponent>
                ) : (
                  <WebComponent title={'WebComponent'}></WebComponent>  
                )}
              </>
            )}
        </div>
    )
}

export default MainComponent;

