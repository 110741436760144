export class BuckState {
    bucks: any[] = [];
    notes: any[] = [];
    isFetching: boolean = false;
    showBucks: boolean = false;
    nowShowing: string = '';
    queryTitle: string = '';
    queryDesc: string = '';
    actionLoaded: ActionLoaded = ActionLoaded.none;
    buck: Partial<IBuck> = {};
    dashboard: any = {};
    buckQuery: IBuckQuery = {searchSN:"B55555555*",selectedMAT:{IsStarNote: true}};
}

export enum ActionLoaded {
    none,
    loadAllImportedSuccess,
    loadAllExportedSuccess,
    loadAllNotImportedSuccess,
    loadAllNotExportedSuccess,
    loadMostRecentValuableSuccess,
    loadAllValuableSuccess,
    loadBuckSuccess,
    searchWithMatchesSuccess,
    searchTopHistorySuccess,
    searchBucksSuccess,
}

export const initialState:BuckState = {
    bucks: [],
    notes: [],
    isFetching: false,
    showBucks: false,
    nowShowing: '',
    queryTitle: '',
    queryDesc: '',
    dashboard: {
        districtChartData: null, 
        isDistrictDataFetching: false, 
        topHistoryChartData: null, 
        isTopHistoryChartDataFetching: false,
        topRatedChartData: null, 
        topRatedChartDataFetching: false,
    },
    actionLoaded: ActionLoaded.none,
    buck: {_id:'B55555555*', sn:'B55555555*', MAT:{IsTwoDigits:false}},
    buckQuery: {searchSN:"B55555555*", selectedMAT:{
        IsAllPairs2: false, 
        IsAllPairs4: false, 
        IsRepeatingPairs2: false, 
        IsRepeatingPairs4: false, 
        IsConsectutiveAscending: false, 
        IsConsectutiveDescending: false, 
        IsConsectutivePairAscending: false, 
        IsConsectutivePairDescending: false, 
        IsUniqueDigits: false, 
        IsOneDigit: false, 
        IsTwoDigits: false, 
        IsSixOrMoreSameDigit: false, 
        IsPalindrome: false, 
        IsDate: false, 
        IsEuroDate: false, 
        IsStarNote: false},
        searchBuck:{_id:'',PX:'A',SX:'A'}},
};
export interface IBuck {
    _id: string;
    sn?: string;
    SD?: string;
    FRD?: string;
    FRL?: string;
    PX?: string;
    SX?: string;
    CDT?: string;
    UDT?: string;
    MAT?: Partial<IMatch>;
    HIST?: any[];
    CA?: boolean;
    B64_CAP?: string;
    NoteID?: string;
}


export interface IBuckQuery {
    searchSN: string;
    selectedMAT: IMatch;
    searchBuck?: IBuck;
    serialNumberList?: string[];
    beginDate?: Date;
    endDate?: Date;
}

export class BuckQueryRequest {
    searchTerm = '';
    searchField = '';
    serialNumberList = [];
}

export interface IMatch {
    IsAllPairs2?: boolean;
    IsAllPairs4?: boolean;
    IsRepeatingPairs2?: boolean;
    IsRepeatingPairs4?: boolean;
    IsConsectutiveAscending?: boolean;
    IsConsectutiveDescending?: boolean;
    IsConsectutivePairAscending?: boolean;
    IsConsectutivePairDescending?: boolean;
    IsUniqueDigits?: boolean;
    IsOneDigit?: boolean;
    IsTwoDigits?: boolean;
    IsSixOrMoreSameDigit?: boolean;
    InARowCount?: number;
    IsPalindrome?: boolean;
    IsDate?: boolean;
    IsDateDate?: any;
    IsEuroDate?: boolean;
    IsEuroDateDate?: any;
    RatingValue?: number;
    IsStarNote: boolean;
}