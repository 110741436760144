import { ofType } from "redux-observable";
import { mergeMap, map, catchError, startWith} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { addBucksSqlToBucksMongo, addNotesToBuckTable, fetchAllExported, fetchAllImported, fetchAllNotExported, fetchAllNotImported, fetchAllValuable, fetchBuck, fetchBucks, fetchDashboardDistrictCounts, fetchDashboardTopRatedChartData, fetchDashboardTopHistoryChartData, fetchHighestRatedBucksFromMatches, fetchMostRecentValuable, fetchTopHistory } from "../../services/buck.service";
import { DoExport, DoImport, LoadAllExported, LoadAllImported, LoadAllNotExported, LoadAllNotImported, LoadAllValuable, LoadBuck, LoadDashboardDistrictCounts, LoadMostRecentValuable, SearchBucks, SearchTopHistory, SearchWithMatches, isFetching, loadAllExportedFailed, loadAllExportedSuccess, loadAllImportedFailed, loadAllImportedSuccess, loadAllNotExported, loadAllNotExportedFailed, loadAllNotExportedSuccess, loadAllNotImported, loadAllNotImportedFailed, loadAllNotImportedSuccess, loadAllValuableFailed, loadAllValuableSuccess, loadBuck, loadBuckFailed, loadBuckSuccess, loadDashboardDistrictCountsFailed, loadDashboardDistrictCountsSuccess, loadDashboardTopRatedChartDataFailed, loadDashboardTopRatedChartDataSuccess, loadDashboardTopHistoryChartDataFailed, loadDashboardTopHistoryChartDataSuccess, loadMostRecentValuableFailed, loadMostRecentValuableSuccess, searchBucksSuccess, searchTopHistoryFailed, searchTopHistorySuccess, searchWithMatchesFailed, searchWithMatchesSuccess } from "./buck.actions";


export const doImportEpic = (action$:Observable<any>) => action$.pipe(
    ofType(DoImport),
    mergeMap(() => addNotesToBuckTable().pipe(
        map(()=>loadAllNotImported()),
    )
));

export const doExportEpic = (action$:Observable<any>) => action$.pipe(
    ofType(DoExport),
    mergeMap(() => addBucksSqlToBucksMongo().pipe(
        map(()=>loadAllNotExported()),
    )
));

export const loadAllImportedEpic = (action$:Observable<any>) => action$.pipe(
    ofType(LoadAllImported),
    mergeMap(() => fetchAllImported().pipe(
        map((response:any) => {
            return loadAllImportedSuccess(response);
        }),
        catchError(() => of(loadAllImportedFailed())),
    )
));

export const loadAllNotImportedEpic = (action$:Observable<any>) => action$.pipe(
    ofType(LoadAllNotImported),
    mergeMap(() => fetchAllNotImported().pipe(
        map((response:any) => {
            // response.map((r:any) => r.LastUpdate = `${new Date(r.LastUpdate).toLocaleDateString()} ${new Date(r.LastUpdate).toLocaleTimeString()}`);
            return loadAllNotImportedSuccess(response);
        }),
        catchError(() => of(loadAllNotImportedFailed())),
    )
));

export const loadAllExportedEpic = (action$:Observable<any>) => action$.pipe(
    ofType(LoadAllExported),
    mergeMap(() => fetchAllExported().pipe(
        map((response:any) => {
            response.map((r:any) => r.BatchDate = `${new Date(r.CDT).toLocaleDateString()} ${new Date(r.CDT).toLocaleTimeString()}`);
            response.map((r:any) => r.RatingValue = r.MAT.RatingValue);
            response.map((r:any) => r.SN = r._id);
            
            console.log("response", response);
            return loadAllExportedSuccess(response);
        }),
        catchError(() => of(loadAllExportedFailed())),
    )
));

export const loadAllNotExportedEpic = (action$:Observable<any>) => action$.pipe(
    ofType(LoadAllNotExported),
    mergeMap(() => fetchAllNotExported().pipe(
        map((response:any) => {
            response.map((r:any) => r.BatchDate = `${new Date(r.CDT).toLocaleDateString()} ${new Date(r.CDT).toLocaleTimeString()}`);
            console.log("response", response);
            // response.map((r:any) => r.LastUpdate = `${new Date(r.LastUpdate).toLocaleDateString()} ${new Date(r.LastUpdate).toLocaleTimeString()}`);
            return loadAllNotExportedSuccess(response);
        }),
        catchError(() => of(loadAllNotExportedFailed())),
    )
));

export const loadAllValuableEpic = (action$:Observable<any>) => action$.pipe(
    ofType(LoadAllValuable),
    mergeMap(() => fetchAllValuable().pipe(
        map((response:any) => {
            return loadAllValuableSuccess(response);
        }),
        catchError(() => of(loadAllValuableFailed())),
    )
));

export const loadMostRecentValuableEpic = (action$:Observable<any>) => action$.pipe(
    ofType(LoadMostRecentValuable),
    mergeMap(() => fetchMostRecentValuable().pipe(
        map((response:any) => {
            return loadMostRecentValuableSuccess(response);
        }),
        catchError(() => of(loadMostRecentValuableFailed())),
        startWith(isFetching())
    )
));


// web epics
export const loadBuckEpic = (action$:Observable<any>) => action$.pipe(
    ofType(LoadBuck),
    mergeMap((action) => fetchBuck(action.payload).pipe(
        map((response:any) => {
            return loadBuckSuccess(response);
        }),
        catchError(() => of(loadBuckFailed())),
        startWith(isFetching())
    )
));

export const searchWithMatchesEpic = (action$:Observable<any>) => action$.pipe(
    ofType(SearchWithMatches),
    mergeMap((action) => fetchHighestRatedBucksFromMatches(action.payload).pipe(
        map((payload:any) => {
            const resp = Array.isArray(payload.response) ? payload.response : [];
            return searchWithMatchesSuccess(resp);
        }),
        catchError(() => of(searchWithMatchesFailed())),
        startWith(isFetching())
    )
));

export const searchTopHistoryEpic = (action$:Observable<any>) => action$.pipe(
    ofType(SearchTopHistory),
    mergeMap(() => fetchTopHistory().pipe(
        map((payload:any) => {
            return searchTopHistorySuccess(payload);
        }),
        catchError(() => of(searchTopHistoryFailed())),
        startWith(isFetching())
    )
));

export const searchBucksEpic = (action$:Observable<any>) => action$.pipe(
    ofType(SearchBucks),
    mergeMap((action) => fetchBucks(action.payload[0], action.payload[1]).pipe(
        map((payload:any) => {
            const resp = Array.isArray(payload.response) ? payload.response : [];
            return searchBucksSuccess(resp);
        }),
        catchError(() => of(searchTopHistoryFailed())),
        startWith(isFetching())
    )
));

export const loadDashboardDistrictCountsEpic = (action$:Observable<any>) => action$.pipe(
    ofType(LoadDashboardDistrictCounts),
    mergeMap(() => fetchDashboardDistrictCounts().pipe(
        map((payload:any) => {
            return loadDashboardDistrictCountsSuccess(payload);
        }),
        catchError(() => of(loadDashboardDistrictCountsFailed())),
        startWith(isFetching())
    )
));

export const loadDashboardTopHistoryChartDataEpic = (action$:Observable<any>) => action$.pipe(
    ofType(LoadDashboardDistrictCounts),
    mergeMap(() => fetchDashboardTopHistoryChartData().pipe(
        map((payload:any) => {
            return loadDashboardTopHistoryChartDataSuccess(payload);
        }),
        catchError(() => of(loadDashboardTopHistoryChartDataFailed())),
        startWith(isFetching())
    )
));

export const loadDashboardTopRatedChartDataEpic = (action$:Observable<any>) => action$.pipe(
    ofType(LoadDashboardDistrictCounts),
    mergeMap(() => fetchDashboardTopRatedChartData().pipe(
        map((payload:any) => {
            return loadDashboardTopRatedChartDataSuccess(payload);
        }),
        catchError(() => of(loadDashboardTopRatedChartDataFailed())),
        startWith(isFetching())
    )
));
